.account {
    margin: 24px 0;
    display: flex;
    justify-content: center;
    margin: 80px 0;
}

.account form{
    width: 40%;
    background-color: #d2d2d2;
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px, rgba(0, 0, 0, 0.8);
}


.account label {
    text-align: left;
    display: block;
    margin-top: 8px;
    font-size: 18px;
}

.account input {
    background-color: #fefefe;
    box-shadow: 10px 10px 30px rgbs(0, 0, 0, 0.6);
    width: 100%;
    border: none;
    font-size: 18px;
    padding: 5px;
    box-sizing: border-box;
}

.account button {
    background-color: #7278f7;
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    padding: 16px;
    border: none;
    cursor: pointer;
    color: #fff;
    margin: 32px 0;
}

.account button:hover {
    background-color: #5258d7;
}

.errorMessage {
    font-size: 18px;
    font-weight: 1400;
    color: red;
}